import * as React from "react";
import {
  SimpleForm,
  Edit,
  TextInput,
  BooleanInput,
  usePermissions,
  Loading,
  SelectInput,
  Labeled,
  DataProviderContext,
  useRefresh,
  useRecordContext,
} from "react-admin";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { InfoGrid, ValidationGrid } from "./InfoGrid";
import { MetaData } from "./MetaData";
import { Reviews } from "./Reviews";
import { ForcesWeakness } from "./ForcesWeakness";
import { useStyles } from "./useStyles";
import { WebpageAside } from "./WebpageAside";
import { WebpageDetails } from "./WebpageDetails";
import { usePage } from "../../page/usePage";
import { WriterReferenceField, WriterReferenceInput } from "../../writer";
import { CONTENT_PRIORITY_CHOICES, STATUS, GROUPS } from "../../constants";
import {
  ArrayTextInput,
  ChipArrayField,
  HorizontalGroup,
} from "../../reusable";
import { UserReferenceField, UserReferenceInput } from "../../user";
import BrandReferenceInput from "./BrandReferenceInput";
import { RESOURCE } from "../refusal/constants";
import { ProofreaderReferenceInput } from "../../proofreader/inputs/ProofreaderReferenceInput";
import { VAssistantReferenceInput } from "../../vassistant/inputs/VAssistantReferenceInput";
import DisplayContentSections from "./ContentSections";
import { AdvancedInput } from "./AdvancedInput";
import { YoutubeReferenceInput } from "../../youtube/inputs";
import { dataProvider } from "../../reusable/store";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  RESOURCE as RESOURCES,
  STATUS as CONTENT_STATUS,
} from "../../constants";
import { ContentCharacterReferenceField } from "../content_character/inputs/ContentCharacterReferenceField";
import RateDiscrepancyCard from "../change_writer_rate/RateDiscrepancyCard";
import ContentEditable from "react-contenteditable";

const StatusCheck = ({ record, ...props }) => (
  <BooleanInput
    {...props}
    parse={(value) => (value === true ? STATUS.published : record.status)}
    format={(status) => status === STATUS.published}
  />
);
StatusCheck.defaultProps = {
  addLabel: true,
};

const DisplayWriterField = ({
  classes,
  isProofreader,
  isVAssistant,
  isFreelance,
  ...props
}) => {
  const record = useRecordContext(props);
  const EDITABLE_STATUSES = [
    CONTENT_STATUS.requested,
    CONTENT_STATUS.review,
    CONTENT_STATUS.va_reviewed,
  ];
  const canView = !isProofreader || !isVAssistant;
  const readOnly = isFreelance || !EDITABLE_STATUSES.includes(record.status);
  return (
    canView && (
      <Labeled label={"Writer"} className={classes.big}>
        {readOnly ? (
          <WriterReferenceField source="writer" link={false} label={"Writer"} />
        ) : (
          <WriterReferenceInput source="writer" link={false} label={"Writer"} />
        )}
      </Labeled>
    )
  );
};

export const EditForm = withStyles({
  card: {
    // overflow: "initial"
  },
  root: {
    maxWidth: "100%",
    // // sidebar (not present)
    "& > div:nth-child(1)": {
      display: "none",
    },
    // main content
    "&>div:nth-child(2)": {
      display: "flex",
    },
  },
})(({ toolbar, readOnly, editableUrl, ...props }) => {
  const [activeUsers, setActiveUsers] = React.useState([]);
  const classes = useStyles();
  const { permissions, loading } = usePermissions();
  if (loading) return <Loading />;
  const isFreelance = permissions.groups.includes(GROUPS.freelances);
  const isWriter = permissions.groups.includes(GROUPS.writers);
  const isProofreader = permissions.groups.includes(GROUPS.proofreader);
  const isVAssistant = permissions.groups.includes(GROUPS.vAssistant);

  return (
    <Edit
      aside={
        <WebpageAside className={classes.aside} activeUsers={activeUsers} />
      }
      mutationMode="pessimistic"
      title={<Title />}
      className={props.classes.root}
      transform={(data) => ({
        ...data,
        content: data.content || "",
        writer_template_rate: data.writer_template_rate || 0.0,
        pbn_promoted_url: data.pbn_promoted_url || "",
        pbn_content_title: data.pbn_content_title || "",
      })}
      {...props}
    >
      <SimpleForm toolbar={toolbar} className={classes.editForm}>
        <LiveUsers activeUsers={activeUsers} setActiveUsers={setActiveUsers} />
        <DisplayContentFlag {...props} />
        {!isVAssistant && <RefusalReason />}
        <HorizontalGroup>
          <Labeled label={"Requested by"} className={classes.big}>
            <UserReferenceField
              source="created_by"
              link={false}
              label={"Requested by"}
            />
          </Labeled>
          <Labeled label={"Approved by"} className={classes.big}>
            <UserReferenceField
              source="approved_by"
              link={false}
              label={"Approved by"}
            />
          </Labeled>
          <Labeled label={"Assigned Integrator"} className={classes.big}>
            <UserReferenceInput
              resettable
              source="integrator"
              link={false}
              label={"Assigned Integrator"}
              filter={{ groups__name: GROUPS.integrators }}
            />
          </Labeled>
        </HorizontalGroup>
        <RateDiscrepancyCard />

        <HorizontalGroup>
          <DisplayWriterField
            classes={classes}
            isProofreader={isProofreader}
            isFreelance={isFreelance}
            isVAssistant={isVAssistant}
          />
          {!isFreelance && (
            <Labeled label={"Proofreader"} className={classes.big}>
              <ProofreaderReferenceInput
                source="proofreader"
                link={false}
                label={"Proofreader"}
                resettable
              />
            </Labeled>
          )}
          {!isFreelance && (
            <Labeled label={"Virtual Assistant"} className={classes.big}>
              <VAssistantReferenceInput
                source="vassistant"
                link={false}
                label={"Virtual Assistant"}
                resettable
              />
            </Labeled>
          )}
        </HorizontalGroup>

        <HorizontalGroup>
          <TextInput
            source={"type"}
            className={classes.big}
            disabled={isFreelance}
          />
          <TextInput
            source={"google_docs"}
            label="Template in GDOCS"
            className={classes.bigAndBigger}
            disabled={isFreelance}
          />
        </HorizontalGroup>
        <HorizontalGroup>
          <BrandReferenceInput
            source={"structure.brand"}
            label={"Link to a brand"}
            className={classes.bigAndBigger}
          />
        </HorizontalGroup>
        {!isFreelance && (
          <HorizontalGroup>
            <TextInput
              className={classes.bigger}
              source={"title"}
              label="Youtube Order title"
            />
            <YoutubeReferenceInput
              source={"youtube"}
              label={"Content for Youtube Channel"}
              className={classes.bigAndBigger}
            />
          </HorizontalGroup>
        )}
        {!isFreelance && (
          <TextInput
            label={"Writer Template Rate for this Content"}
            className={classes.bigAndBigger}
            source="writer_template_rate"
            min={0}
          />
        )}
        <WebpageDetails editable={!isFreelance} fullWidth />

        {isFreelance ? (
          <ChipArrayField source={"examples"} link label={"Example Urls"} />
        ) : (
          <ArrayTextInput
            source={"examples"}
            label={"Example urls"}
            className={classes.bigAndBigger}
            helperText={"Reference urls, use a new line for each one"}
          />
        )}
        {editableUrl ? (
          <TextInput
            fullWidth
            source="new_path"
            label={"Change the path"}
            parse={(value) => {
              try {
                const origin = new URL(value).origin;
                return value.slice(origin.length);
              } catch (_) {
                return value;
              }
            }}
          />
        ) : null}
        {editableUrl ? (
          <StatusCheck label={"has it been published?"} source="status" />
        ) : null}

        <InfoGrid isFreelance={isFreelance} />

        <DisplayContentCharacter />

        <AdvancedInput
          disabled={!!isFreelance || !!isWriter}
          label={"Instructions"}
          source={"structure.instructions"}
          emptyText={"No instructions provided"}
        />

        <TextInput
          multiline
          fullWidth
          disabled={!!isFreelance}
          label={"Reader's Intent"}
          source="structure.reader_intent"
        />

        <DisplayWriteNotes disabled={!isFreelance} {...props} />

        <BooleanInput
          disabled={!!isFreelance || !!isWriter}
          label={"Require Meta Data"}
          source="editable_metas"
        />
        <MetaData isFreelance={isFreelance} className={classes.accordion} />
        <DisplayWriterJustification />
        <DisplayContentEditor
          isWriter={isWriter}
          isVAssistant={isVAssistant}
          {...props}
        />

        <ForcesWeakness className={classes.accordion} />

        <Reviews className={classes.accordion} />
        {!isFreelance ? (
          <HorizontalGroup label="Changes to the order">
            <SelectInput
              source="content_priority"
              choices={CONTENT_PRIORITY_CHOICES}
              className={classes.big}
            />
            <SelectInput
              key="editable_metas"
              source="editable_metas"
              label="Meta values"
              choices={[
                { id: false, name: "non editable" },
                { id: true, name: "editable" },
              ]}
            />
            <BooleanInput
              source="has_scheduled"
              label={"Hide the Content from the Writer"}
              className={classes.bigAndBigger}
              helperText={
                "If the content is hidden that means the writer can see the content 7 days before the ETA. Uncheck this if you want the writer to see the content immediately."
              }
            />
          </HorizontalGroup>
        ) : null}

        <ValidationGrid />
      </SimpleForm>
    </Edit>
  );
});

const DisplayWriteNotes = ({ record, disabled, ...props }) => {
  const classes = useStyles();
  const text = React.useRef(record?.structure?.writer_notes || "");
  if (!record) return null;

  const handleChange = (evt) => {
    text.current = evt.target.value;
  };

  const saveNotes = async () => {
    await dataProvider.update(RESOURCES.order, {
      id: record.id,
      data: {
        structure: { ...record.structure, writer_notes: text.current },
      },
    });
  };

  return (
    <>
      <h4>Writer Notes</h4>
      <ContentEditable
        disabled={disabled}
        className={classes.editableDiv}
        html={text.current}
        onChange={handleChange}
        onBlur={saveNotes}
      />
    </>
  );
};

const DisplayWriterJustification = ({ record, ...props }) => {
  const justification = record?.structure?.writer_justification;
  if (!justification) return null;
  return (
    <TextInput
      multiline
      fullWidth
      disabled
      label={"Writer's Justification"}
      source="structure.writer_justification"
    />
  );
};

const Title = ({ record, ...props }) => {
  const { loaded, error, data } = usePage(record.webpage);
  if (!loaded) return `Order ${record.id}`;
  if (error) return `Order ${record.id}`;
  return `Order ${record.id} - ${data?.keywords[0]} - ${data?.full_url}`;
};

const DisplayContentEditor = ({ isWriter, isVAssistant, ...props }) => {
  const classes = useStyles();
  const { record } = props;
  const [loading, setLoading] = React.useState(false);
  const refresh = useRefresh();
  if (!isWriter && !isVAssistant) {
    return <DisplayContentSections {...props} />;
  }

  const saveContent = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {};
    if (isWriter) {
      data["has_started_writing"] = new Date().toISOString();
    } else if (isVAssistant) {
      data["va_started_reviewing"] = new Date().toISOString();
    }
    await dataProvider.update(RESOURCES.order, {
      id: record.id,
      data,
    });
    setLoading(false);
    refresh();
  };

  return (isWriter && record.has_started_writing) ||
    (isVAssistant && record.va_started_reviewing) ? (
    <DisplayContentSections {...props} />
  ) : (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Paper className={classes.paper}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="h5" color="textPrimary">
              <strong>Your Text Editor has been hidden!</strong>
            </Typography>
            <Typography variant="p" color="textPrimary">
              <p>
                Please Press <strong>View Editor</strong> for the Editors to
                appear. Keep in mind that the PMs will be notified and keep
                track of when you have started writing or reviewing the content.
              </p>
            </Typography>
            <Button
              id="writer-start-writing"
              onClick={saveContent}
              variant="contained"
              color="primary"
            >
              View Editor
            </Button>
          </Box>
        </Paper>
      )}
    </>
  );
};

export const RefusalReason = ({ record }) => {
  const [reasons, setReasons] = React.useState("");

  const dataProvider = React.useContext(DataProviderContext);

  React.useEffect(() => {
    // console.log("[refusal reason]", record.id);
    const getData = async () => {
      const response = await dataProvider.getList(RESOURCE, {
        pagination: {},
        sort: {},
        filter: { content: record.id },
      });
      let reasons_string = "";
      for (const data of response.data) {
        reasons_string += data?.reason + "\r\n\n";
      }
      // console.log("refusal", reasons_string);
      setReasons(reasons_string);
    };
    getData();
  }, [record, dataProvider]);

  if (record.feedback) {
    return (
      <div>
        <TextField
          value={`${record.vote} / 5`}
          variant="filled"
          disabled
          label={"vote"}
        />
        <TextField
          value={record.feedback}
          multiline
          variant="filled"
          disabled
          label={"Final Feedback"}
          fullWidth={true}
        />
        {reasons && (
          <TextField
            value={reasons}
            multiline
            variant="filled"
            disabled
            label={"Review Feedback"}
            fullWidth={true}
          />
        )}
      </div>
    );
  }
  if (reasons) {
    return (
      <TextField
        value={reasons}
        multiline
        variant="filled"
        disabled
        label={"Review Feedback"}
        fullWidth={true}
      />
    );
  } else {
    return [];
  }
};

const DisplayContentCharacter = ({ props }) => {
  const classes = useStyles();
  const record = useRecordContext(props);
  const content_character = record?.structure?.content_characture;
  if (!content_character) return null;

  return (
    <Card className={classes.lightYellow}>
      <CardHeader subheader={"Content Character"}></CardHeader>
      <CardContent>
        <ContentCharacterReferenceField
          source={"structure.content_characture"}
        />
      </CardContent>
    </Card>
  );
};

export const DisplayContentFlag = ({ props }) => {
  const classes = useStyles();
  const record = useRecordContext(props);
  const content_flag = record?.structure?.flagData;
  if (!content_flag) return null;
  const flag = content_flag.flag;
  if (!flag || flag === "") return null;
  const subHeader = "Content Flagged as " + content_flag.flag.toUpperCase();

  return (
    <Card className={classes.lightRed}>
      <CardContent>{subHeader}</CardContent>
    </Card>
  );
};

const LiveUsers = ({ activeUsers, setActiveUsers, ...props }) => {
  const record = useRecordContext(props);
  const [username, setUsername] = React.useState(
    localStorage.getItem("username"),
  );
  const socketRef = React.useRef(null);

  React.useEffect(() => {
    setUsername(localStorage.getItem("username"));
  }, []);

  React.useEffect(() => {
    if (!username) {
      return;
    }
    socketRef.current = new WebSocket(
      `wss://edge-chat-demo.tech910.workers.dev/api/room/room-${record.id}/websocket`,
    );
    socketRef.current.onopen = () => {
      socketRef.current.send(JSON.stringify({ name: username }));
    };
    socketRef.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.joined) {
        setActiveUsers((prevUsers) => {
          if (prevUsers.includes(message.joined)) {
            return prevUsers;
          }
          return [...prevUsers, message.joined];
        });
      }
      if (message.quit) {
        setActiveUsers((prevUsers) => {
          return prevUsers.filter((user) => user !== message.quit);
        });
      }
    };

    socketRef.current.onclose = () => {};

    return () => {
      socketRef.current.close();
    };
  }, [record.id, username, setActiveUsers]);
  return <></>;
};
