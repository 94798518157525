import {
  TextInput,
  SearchInput,
  SelectInput,
  ReferenceInput,
  SelectArrayInput,
} from "react-admin";
import { MarketReferenceArrayInput, MarketReferenceInput } from "../../market";
import { CHANNEL, DEAL } from "../deals/constants";
import { VerticalReferenceArrayInput } from "../../vertical";
import { makeStyles, Chip } from "@material-ui/core";
import { BrandReferenceInput } from "../brands";
import {
  WebsiteReferenceArrayInput,
  WebsiteReferenceInput,
} from "../../website";

import { PaymentStatus, ListingTypes, PaymentTypes } from "./List";
import { DateRange, DateRangeDialog } from "../../reusable";
import { YoutubeReferenceArrayInput } from "../../youtube/inputs";
import {
  LISTING_FEE_OWNERS,
  LISTING_FEE_OWNERS_CHOICES,
  LISTING_FEE_VERTICAL_CHOICES,
} from "../../constants";

const useStyles = makeStyles((theme) => ({
  multipleInclude: {
    "& .MuiInputBase-root": {
      width: "10rem",
    },
  },
}));

export const GetFilters = () => {
  const classes = useStyles();
  return [
    <SearchInput source="q" alwaysOn />,
    <BrandReferenceInput
      source="brand"
      label="Brand"
      alwaysOn
      resettable
      allowEmpty={false}
    />,
    <MarketReferenceInput
      label="Market"
      source="market"
      alwaysOn
      resettable
      allowEmpty={false}
    />,
    <WebsiteReferenceArrayInput
      source="websites"
      label="Website"
      alwaysOn
      resettable
      allowEmpty={false}
    />,
    <YoutubeReferenceArrayInput
      source="youtubes"
      label="Youtube"
      alwaysOn
      resettable
      allowEmpty={false}
    />,
    <SelectArrayInput
      className={classes.multipleInclude}
      choices={Object.keys(ListingTypes).map((key) => ({
        id: key,
        name: ListingTypes[key as keyof typeof ListingTypes],
      }))}
      label="Listing Types"
      emptyText={"All"}
      source="listing_type"
      resettable
      alwaysOn
    />,
    <SelectInput
      className={classes.multipleInclude}
      choices={Object.keys(PaymentTypes).map((key) => ({
        id: key,
        name: PaymentTypes[key as keyof typeof PaymentTypes],
      }))}
      label="Payment Type"
      emptyText={"All"}
      source="payment__type"
      resettable
      alwaysOn
    />,
    <SelectInput
      className={classes.multipleInclude}
      choices={Object.keys(PaymentStatus).map((key) => ({
        id: key,
        name: PaymentStatus[key as keyof typeof PaymentStatus],
      }))}
      label="Payment Status"
      emptyText={"All"}
      source="payment__status"
      resettable
      alwaysOn
    />,
    <SelectInput
      className={classes.multipleInclude}
      choices={LISTING_FEE_OWNERS_CHOICES}
      label="Fee Owner"
      emptyText={"All"}
      source="fee_owner"
      resettable
      alwaysOn
    />,
    <DateRangeDialog
      label={"Date Paid"}
      sourceBefore={"paid_end"}
      sourceAfter={"paid_start"}
      startDate={null}
      endDate={null}
      alwaysOn
      resettable
    />,
    <DateRangeDialog
      label={"Start Date"}
      sourceBefore={"start_date_end"}
      sourceAfter={"start_date_start"}
      startDate={null}
      endDate={null}
      alwaysOn
      resettable
    />,
    <DateRangeDialog
      label={"End Date"}
      sourceBefore={"end_date_end"}
      sourceAfter={"end_date_start"}
      startDate={null}
      endDate={null}
      alwaysOn
      resettable
    />,
    <DateRangeDialog
      label={"Fee Date"}
      sourceBefore={"fee_date_end"}
      sourceAfter={"fee_date_start"}
      startDate={null}
      endDate={null}
      alwaysOn
      resettable
    />,
    <SelectInput
      label="Vertical"
      source="vertical"
      choices={LISTING_FEE_VERTICAL_CHOICES}
      emptyText={"All"}
      alwaysOn
      resettable
    />,
  ];
};

export default GetFilters;
