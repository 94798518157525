import React from "react";

import {
  List as RAList,
  Datagrid,
  ListProps,
  TextField,
  DateField,
  downloadCSV,
  EditButton,
  FunctionField,
  Pagination,
} from "react-admin";

import GetFilters from "./Filters";
import { MarketArrayChipField } from "../../market";
import { WebsiteReferenceArrayField } from "../../website/fields";
import jsonexport from "jsonexport/dist";
import { todayISOString } from "../../reusable";
import { API_URL, LISTING_FEE_OWNERS, RESOURCE } from "../../constants";
import { YoutubeReferenceArrayField } from "../../youtube/fields";
import { makeStyles } from "@material-ui/core/styles";
import Actions from "./Actions";
import { Typography } from "@material-ui/core";
import { httpClient } from "../../utils";
import { PMBrandReferenceField } from "../pmBrands/fields/PMBrandReferencefield";

const useStyles = makeStyles(() => ({
  yellowSpan: {
    backgroundColor: "#facc15",
    padding: "0.5rem",
    borderRadius: "0.5rem",
  },
  redSpan: {
    color: "white",
    backgroundColor: "#ef4444",
    padding: "0.5rem",
    borderRadius: "0.5rem",
  },
  greenSpan: {
    color: "#052e16",
    backgroundColor: "#00f500",
    padding: "0.5rem",
    borderRadius: "0.5rem",
  },
  blueSpan: {
    color: "white",
    backgroundColor: "#3b82f6",
    padding: "0.5rem",
    borderRadius: "0.5rem",
  },

  extendedWidth: {
    // width: "100vw",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    "&>p": {
      paddingLeft: "1.5rem",
      fontSize: ".8rem",
      "& b": {
        paddingRight: ".5rem",
      },
    },
  },
}));

const getCSS = (classes: any, color: string | undefined) => {
  switch (color) {
    case "YELLOW":
      return classes.yellowSpan;
    case "RED":
      return classes.redSpan;
    case "GREEN":
      return classes.greenSpan;
    case "BLUE":
      return classes.blueSpan;
    default:
  }
};

export const ListingTypes = {
  listing_fee: "Listing Fee",
  flat_fee: "Flat Fee",
  video_review: "Video Review",
  youtube_top: "Youtube Top",
};

export const PaymentTypes = {
  bank_transfer: "Bank Transfer",
  crypto_transfer: "Crypto Transfer",
  in_comission: "In Comission",
  other: "Other",
};

export const PaymentStatus = {
  unpaid: "Unpaid",
  paid: "Paid",
  on_going: "On Going",
  cancelled: "Cancelled",
  withdrawn: "Withdrawn",
};

const Types = ({ record }) =>
  record.type &&
  record.type.map((t: keyof typeof ListingTypes) => <p> {ListingTypes[t]}</p>);

const Payment = ({ record }) =>
  record.payment_type && (
    <p>{PaymentTypes[record.payment_type as keyof typeof PaymentTypes]}</p>
  );

const NewPagination = (props) => {
  const classes = useStyles();
  const [stats, setStats] = React.useState({
    total_amount: 0,
  });
  const { resource, filterValues } = props;
  React.useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(filterValues);
      const url = `${API_URL}/${resource}/total_amount/?${params}`;

      const result = await httpClient(url);
      setStats(result.json);
    };
    fetchData();
  }, [filterValues, setStats, resource]);

  return (
    <span className={classes.footer}>
      <p>
        <span>
          Total Amount earned: <b>{stats.total_amount || "-"} EUR</b>
        </span>
      </p>
      <Pagination {...props} />
    </span>
  );
};

const CustomPaymentField = (props: any) => {
  const classes = useStyles();

  const { record } = props;
  const payment_status = record?.payment_status;

  const getClasses = (status: string) => {
    switch (status) {
      case PaymentStatus.paid.toLowerCase():
        return getCSS(classes, "GREEN");
      case PaymentStatus.unpaid.toLowerCase():
        return getCSS(classes, "YELLOW");
      case PaymentStatus.cancelled.toLowerCase():
        return getCSS(classes, "RED");
      case PaymentStatus.withdrawn.toLowerCase():
        return getCSS(classes, "BLUE");
      default:
    }
  };

  return (
    <TextField
      className={getClasses(payment_status)}
      source="payment_status"
      label="Payment Status"
    />
  );
};

export const List = (props: ListProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <RAList
      {...props}
      className={classes.extendedWidth}
      filters={GetFilters()}
      exporter={exporter}
      pagination={<NewPagination />}
      sort={{ field: "updated", order: "DESC" }}
    >
      <Datagrid style={{ width: "120rem" }}>
        <EditButton />
        <PMBrandReferenceField source="brand" label="Brand" />
        {/* THIS Two fields are depcricated */}
        {/* <WebsiteReferenceArrayField source={"websites"} label="Websites" />
        <YoutubeReferenceArrayField source={"youtubes"} label="Youtubes" /> */}
        <WebsiteReferenceArrayField source={"website"} label="Website" />
        <YoutubeReferenceArrayField source={"youtube"} label="Youtube" />
        <MarketArrayChipField source="markets" label="Markets" />
        <Types label="Type" />
        <TextField source="amount" />
        <TextField source="received_amount" />
        <Payment label="Payment Type" />

        <CustomPaymentField label="Payment Status" />
        <TextField source="paid" label="Payment Date" emptyText="-" />
        <TextField source="invoice_details" />
        <TextField source="contact_person" />
        <TextField source="information" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <DateField source="fee_date" />
        <FunctionField
          label="Fee Owner"
          render={(record) => {
            const entry = LISTING_FEE_OWNERS.find(
              (entry) => entry.value === record?.fee_owner,
            );
            return (
              <Typography
                className={getCSS(classes, entry?.color)}
                variant="caption"
              >
                {entry?.label}
              </Typography>
            );
          }}
        />
        {/* <FileField source="invoice_url" title="Invoice Document" /> */}
        <TextField source="invoice_number" title="Invoice Number" />
        <Actions label={"Actions"} />
      </Datagrid>
    </RAList>
  );
};

const strToNum = (str: string) => {
  str = str.trim();
  const num = Number(str);
  if (isNaN(num)) {
    return 0;
  }
  return num;
};

const exporter = async (stats: any, fetchRelatedRecords: any) => {
  const [brand, website, youtube] = await Promise.all([
    fetchRelatedRecords(stats, "brand", "sales/brands"),
    fetchRelatedRecords(stats, "website", RESOURCE.website),
    fetchRelatedRecords(stats, "youtube", RESOURCE.youtube),
  ]);

  let total_amount = 0;

  let data = stats.map((entry: any) => {
    let results = [];
    const fees = entry?.data?.fees || [];

    const csvTypes = (entry.type || [])
      .map((type) => ListingTypes[type])
      .join("\n");

    total_amount += strToNum(entry.amount);

    for (const fee of fees) {
      const csvWebsites = (fee.websites || [])
        .map((website_id) => website[website_id]?.domain)
        .join("\n");
      const csvYoutubes = (fee.youtubes || [])
        .map((youtube_id) => youtube[`${youtube_id}`]?.channel_name)
        .join("\n");

      // adding the product name in the csv
      const websiteProducts = (fee.websites || [])
        .map((website_id) => website[website_id]?.dixit_product_name)
        .join("\n");
      const youtubeProducts = (fee.youtubes || [])
        .map((youtube_id) => youtube[`${youtube_id}`]?.dixit_product_name)
        .join("\n");

      results.push({
        Brand: brand[entry.brand]?.name,
        Websites: csvWebsites,
        Youtubes: csvYoutubes,
        Types: csvTypes,
        Amount: fee.amount,
        "Product Name": websiteProducts + youtubeProducts,
        "Payment Status": PaymentStatus[entry.payment_status],
        "Payment Type": PaymentTypes[entry.payment_type],
        "Payment Date": entry.paid ? entry.paid : "-",
        "Fee Owner": LISTING_FEE_OWNERS.find(
          (user) => user.value === entry.fee_owner,
        )?.label,
        "Start Date": entry.start_date,
        "End Date": entry.end_date,
        "Fee Date": entry.fee_date,
        "Contact Person": entry.contact_person,
        "Invoice Details": entry.invoice_details,
        Information: entry.information,
        "Invoice File URL": entry.invoice_url ? entry.invoice_url : "-",
        Vertical: entry.vertical ? entry.vertical : "-",
      });
    }

    /* this code will be removed in future */
    if (fees.length === 0) {
      const csvWebsites = (entry.websites || [])
        .map((website_id) => website[website_id]?.domain)
        .join("\n");
      const csvYoutubes = (entry.youtubes || [])
        .map((youtube_id) => youtube[`${youtube_id}`]?.channel_name)
        .join("\n");

      results = [
        {
          Brand: brand[entry.brand]?.name,
          Websites: csvWebsites,
          Youtubes: csvYoutubes,
          Types: csvTypes,
          Amount: entry.amount,
          "Payment Status": PaymentStatus[entry.payment_status],
          "Payment Type": PaymentTypes[entry.payment_type],
          "Payment Date": entry.paid ? entry.paid : "-",
          "Fee Owner": LISTING_FEE_OWNERS.find(
            (user) => user.value === entry.fee_owner,
          )?.label,
          "Start Date": entry.start_date,
          "End Date": entry.end_date,
          "Fee Date": entry.fee_date,
          "Contact Person": entry.contact_person,
          "Invoice Details": entry.invoice_details,
          Information: entry.information,
          "Invoice File URL": entry.invoice_url ? entry.invoice_url : "-",
          Vertical: entry.vertical ? entry.vertical : "-",
        },
      ];
    }

    return results;
  });

  // this will add a custom row.
  data.push({
    Types: "Total Amount Earned",
    Amount: total_amount,
  });

  jsonexport(
    data,
    {
      headers: [
        "Brand",
        "Websites",
        "Types",
        "Amount",
        "Payment Status",
        "Payment Type",
        "Payment Date",
        "Fee Owner",
        "Start Date",
        "End Date",
        "Fee Date",
        "Contact Person",
        "Invoice Details",
        "Information",
        "Invoice File URL",
        "Vertical",
      ],
    },
    (err, csv) =>
      !err
        ? downloadCSV(csv, `sales-brand.${todayISOString()}`)
        : console.error(err),
  );
};

export default List;
